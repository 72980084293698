.navbar-interactive1-container {
  display: flex;
  position: relative;
}
.navbar-interactive1-navbar-interactive {
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  padding-top: 32px;
  padding-left: 48px;
  padding-right: 48px;
  padding-bottom: 32px;
  justify-content: space-between;
}
.navbar-interactive1-desktop-menu {
  display: flex;
}
.navbar-interactive1-nav {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  flex-direction: row;
}
.navbar-interactive1-navlink {
  font-style: normal;
  font-weight: 500;
  text-decoration: none;
}
.navbar-interactive1-navlink01 {
  font-style: normal;
  font-weight: 500;
  margin-left: 32px;
  text-decoration: none;
}
.navbar-interactive1-navlink02 {
  font-style: normal;
  font-weight: 500;
  margin-left: 32px;
  text-decoration: none;
}
.navbar-interactive1-navlink03 {
  font-style: normal;
  font-weight: 500;
  margin-left: 32px;
  text-decoration: none;
}
.navbar-interactive1-navlink04 {
  font-style: normal;
  font-weight: 500;
  margin-left: 32px;
  text-decoration: none;
}
.navbar-interactive1-btn-group {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.navbar-interactive1-burger-menu {
  display: none;
  align-items: center;
  justify-content: center;
}
.navbar-interactive1-icon {
  width: 32px;
  height: 32px;
}
.navbar-interactive1-mobile-menu {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  display: none;
  padding: 32px;
  z-index: 100;
  position: fixed;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fff;
}
.navbar-interactive1-nav1 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.navbar-interactive1-container1 {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 48px;
  justify-content: space-between;
}
.navbar-interactive1-menu-close {
  display: flex;
  align-items: center;
  justify-content: center;
}
.navbar-interactive1-nav2 {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.navbar-interactive1-navlink05 {
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.navbar-interactive1-navlink06 {
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.navbar-interactive1-navlink07 {
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.navbar-interactive1-navlink08 {
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.navbar-interactive1-navlink09 {
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.navbar-interactive1-container2 {
  flex: 0 0 auto;
  width: auto;
  border: 2px dashed rgba(120, 120, 120, 0.4);
  display: flex;
  margin-top: var(--dl-space-space-unit);
  align-items: center;
  flex-direction: row;
}
.navbar-interactive1-icon-group {
  display: flex;
}
.navbar-interactive1-icon2 {
  width: 16px;
  height: 16px;
  margin-right: 32px;
}
.navbar-interactive1-icon4 {
  width: 16px;
  height: 16px;
  margin-right: 32px;
}
.navbar-interactive1-icon6 {
  width: 16px;
  height: 16px;
}

@media(max-width: 991px) {
  .navbar-interactive1-navlink {
    font-style: normal;
    font-weight: 500;
  }
  .navbar-interactive1-navlink01 {
    font-style: normal;
    font-weight: 500;
  }
  .navbar-interactive1-navlink02 {
    font-style: normal;
    font-weight: 500;
  }
  .navbar-interactive1-navlink03 {
    font-style: normal;
    font-weight: 500;
  }
  .navbar-interactive1-navlink04 {
    font-style: normal;
    font-weight: 500;
  }
}
@media(max-width: 767px) {
  .navbar-interactive1-navbar-interactive {
    padding-left: 32px;
    padding-right: 32px;
  }
  .navbar-interactive1-navlink01 {
    margin-left: var(--dl-space-space-unit);
  }
  .navbar-interactive1-navlink02 {
    margin-left: var(--dl-space-space-unit);
  }
  .navbar-interactive1-navlink03 {
    margin-left: var(--dl-space-space-unit);
  }
  .navbar-interactive1-navlink04 {
    margin-left: var(--dl-space-space-unit);
  }
}
@media(max-width: 479px) {
  .navbar-interactive1-navbar-interactive {
    padding: var(--dl-space-space-unit);
    padding-bottom: 1em;
  }
  .navbar-interactive1-desktop-menu {
    display: none;
  }
  .navbar-interactive1-btn-group {
    display: none;
  }
  .navbar-interactive1-burger-menu {
    display: flex;
  }
  .navbar-interactive1-mobile-menu {
    padding: 16px;
  }
  .navbar-interactive1-navlink05 {
    font-style: normal;
    font-weight: 500;
    text-decoration: underline;
  }
  .navbar-interactive1-navlink06 {
    font-style: normal;
    font-weight: 500;
    text-decoration: underline;
  }
  .navbar-interactive1-navlink07 {
    font-weight: 500;
    text-decoration: underline;
  }
  .navbar-interactive1-navlink08 {
    font-weight: 500;
    text-decoration: underline;
  }
  .navbar-interactive1-navlink09 {
    font-style: normal;
    font-weight: 500;
    text-decoration: underline;
  }
}
