.oil-pressure-yaml-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  border-color: var(--dl-color-gray-black);
  border-width: 5px;
  flex-direction: column;
}
.oil-pressure-yaml-container01 {
  width: 100%;
  height: 300px;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
  background-size: cover;
  justify-content: flex-start;
  background-image: url("/website%20banner%20no%20logo%20or%20name.png");
}
.oil-pressure-yaml-container02 {
  display: flex;
  position: relative;
}
.oil-pressure-yaml-navbar-interactive {
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  padding-top: 32px;
  padding-left: 48px;
  padding-right: 48px;
  padding-bottom: 32px;
  justify-content: space-between;
}
.oil-pressure-yaml-desktop-menu {
  display: flex;
}
.oil-pressure-yaml-nav {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  flex-direction: row;
}
.oil-pressure-yaml-navlink {
  font-style: normal;
  font-weight: 500;
  text-decoration: none;
}
.oil-pressure-yaml-navlink01 {
  font-style: normal;
  font-weight: 500;
  margin-left: 32px;
  text-decoration: none;
}
.oil-pressure-yaml-navlink02 {
  font-style: normal;
  font-weight: 500;
  margin-left: 32px;
  text-decoration: none;
}
.oil-pressure-yaml-navlink03 {
  font-style: normal;
  font-weight: 500;
  margin-left: 32px;
  text-decoration: none;
}
.oil-pressure-yaml-navlink04 {
  font-style: normal;
  font-weight: 500;
  margin-left: 32px;
  text-decoration: none;
}
.oil-pressure-yaml-btn-group {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.oil-pressure-yaml-burger-menu {
  display: none;
  align-items: center;
  justify-content: center;
}
.oil-pressure-yaml-icon {
  width: 32px;
  height: 32px;
}
.oil-pressure-yaml-mobile-menu {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  display: none;
  padding: 32px;
  z-index: 100;
  position: fixed;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fff;
}
.oil-pressure-yaml-nav1 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.oil-pressure-yaml-container03 {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 48px;
  justify-content: space-between;
}
.oil-pressure-yaml-menu-close {
  display: flex;
  align-items: center;
  justify-content: center;
}
.oil-pressure-yaml-nav2 {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.oil-pressure-yaml-navlink05 {
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.oil-pressure-yaml-navlink06 {
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.oil-pressure-yaml-navlink07 {
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.oil-pressure-yaml-navlink08 {
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.oil-pressure-yaml-navlink09 {
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.oil-pressure-yaml-container04 {
  flex: 0 0 auto;
  width: auto;
  border: 2px dashed rgba(120, 120, 120, 0.4);
  display: flex;
  margin-top: var(--dl-space-space-unit);
  align-items: center;
  flex-direction: row;
}
.oil-pressure-yaml-icon-group {
  display: flex;
}
.oil-pressure-yaml-icon2 {
  width: 16px;
  height: 16px;
  margin-right: 32px;
}
.oil-pressure-yaml-icon4 {
  width: 16px;
  height: 16px;
  margin-right: 32px;
}
.oil-pressure-yaml-icon6 {
  width: 16px;
  height: 16px;
}
.oil-pressure-yaml-image {
  width: 100px;
  align-self: center;
  margin-top: 40px;
  object-fit: cover;
}
.oil-pressure-yaml-container05 {
  width: 400px;
  height: 66px;
  display: flex;
  align-self: center;
  margin-top: 20px;
  align-items: flex-start;
}
.oil-pressure-yaml-text {
  display: block;
  font-size: 32px;
  align-self: center;
  font-style: normal;
  margin-top: 0px;
  font-weight: 800;
  margin-left: auto;
  margin-right: auto;
}
.oil-pressure-yaml-text01 {
  align-self: center;
  margin-top: 50px;
}
.oil-pressure-yaml-container06 {
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.oil-pressure-yaml-link {
  font-size: 14px;
  text-align: justify;
  padding-top: var(--dl-space-space-unit);
  border-color: var(--dl-color-gray-black);
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  text-decoration: none;
}
.oil-pressure-yaml-text07 {
  color: #0000ff;
  text-decoration: underline;
}
.oil-pressure-yaml-link1 {
  display: contents;
}
.oil-pressure-yaml-image1 {
  width: 300px;
  align-self: center;
  object-fit: cover;
  padding-left: var(--dl-space-space-unit);
  text-decoration: none;
}
.oil-pressure-yaml-link2 {
  display: contents;
}
.oil-pressure-yaml-image2 {
  width: 200px;
  align-self: center;
  object-fit: cover;
  padding-top: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.oil-pressure-yaml-container07 {
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
}
.oil-pressure-yaml-container08 {
  width: 435px;
  height: 466px;
  padding-left: var(--dl-space-space-halfunit);
  padding-right: var(--dl-space-space-halfunit);
}
.oil-pressure-yaml-container09 {
  display: contents;
}
.oil-pressure-yaml-footer {
  width: 100%;
  display: flex;
  max-width: 1400px;
  align-items: center;
  padding-top: 32px;
  padding-left: 48px;
  padding-right: 48px;
  flex-direction: column;
  padding-bottom: 32px;
  justify-content: space-between;
}
.oil-pressure-yaml-container10 {
  display: flex;
  max-width: 1400px;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
}
.oil-pressure-yaml-nav3 {
  flex: 0 0 auto;
  display: flex;
  margin-top: 0px;
  align-items: center;
  flex-direction: row;
}
.oil-pressure-yaml-navlink10 {
  margin-left: 0px;
  text-decoration: underline;
}
.oil-pressure-yaml-navlink11 {
  margin-left: var(--dl-space-space-unit);
  text-decoration: underline;
}
.oil-pressure-yaml-navlink12 {
  margin-left: var(--dl-space-space-unit);
  text-decoration: underline;
}
.oil-pressure-yaml-navlink13 {
  margin-left: var(--dl-space-space-unit);
  text-decoration: underline;
}
.oil-pressure-yaml-navlink14 {
  margin-left: var(--dl-space-space-unit);
  text-decoration: underline;
}
.oil-pressure-yaml-separator {
  flex: 0 0 auto;
  width: 100%;
  height: 0px;
  display: flex;
  margin-top: 32px;
  align-items: flex-start;
  margin-left: 0px;
  border-color: var(--dl-color-gray-900);
  border-style: solid;
  border-width: 1px;
  margin-right: 0px;
  margin-bottom: 32px;
  flex-direction: row;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.oil-pressure-yaml-container11 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.oil-pressure-yaml-icon-group1 {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.oil-pressure-yaml-link3 {
  display: contents;
}
.oil-pressure-yaml-image3 {
  width: 100px;
  object-fit: cover;
  text-decoration: none;
}
@media(max-width: 991px) {
  .oil-pressure-yaml-navlink {
    font-style: normal;
    font-weight: 500;
  }
  .oil-pressure-yaml-navlink01 {
    font-style: normal;
    font-weight: 500;
  }
  .oil-pressure-yaml-navlink02 {
    font-style: normal;
    font-weight: 500;
  }
  .oil-pressure-yaml-navlink03 {
    font-style: normal;
    font-weight: 500;
  }
  .oil-pressure-yaml-navlink04 {
    font-style: normal;
    font-weight: 500;
  }
  .oil-pressure-yaml-link {
    border-width: 0px;
  }
  .oil-pressure-yaml-image3 {
    width: auto;
    height: 50px;
  }
}
@media(max-width: 767px) {
  .oil-pressure-yaml-navbar-interactive {
    padding-left: 32px;
    padding-right: 32px;
  }
  .oil-pressure-yaml-navlink01 {
    margin-left: var(--dl-space-space-unit);
  }
  .oil-pressure-yaml-navlink02 {
    margin-left: var(--dl-space-space-unit);
  }
  .oil-pressure-yaml-navlink03 {
    margin-left: var(--dl-space-space-unit);
  }
  .oil-pressure-yaml-navlink04 {
    margin-left: var(--dl-space-space-unit);
  }
  .oil-pressure-yaml-footer {
    padding-left: 32px;
    padding-right: 32px;
  }
  .oil-pressure-yaml-separator {
    margin-top: 24px;
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: 24px;
  }
  .oil-pressure-yaml-container11 {
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
  }
  .oil-pressure-yaml-text11 {
    margin-bottom: 24px;
  }
}
@media(max-width: 479px) {
  .oil-pressure-yaml-container {
    border-width: 3px;
  }
  .oil-pressure-yaml-navbar-interactive {
    padding: var(--dl-space-space-unit);
    padding-bottom: 1em;
  }
  .oil-pressure-yaml-desktop-menu {
    display: none;
  }
  .oil-pressure-yaml-btn-group {
    display: none;
  }
  .oil-pressure-yaml-burger-menu {
    display: flex;
  }
  .oil-pressure-yaml-mobile-menu {
    padding: 16px;
  }
  .oil-pressure-yaml-navlink05 {
    font-style: normal;
    font-weight: 500;
    text-decoration: underline;
  }
  .oil-pressure-yaml-navlink06 {
    font-style: normal;
    font-weight: 500;
    text-decoration: underline;
  }
  .oil-pressure-yaml-navlink07 {
    font-weight: 500;
    text-decoration: underline;
  }
  .oil-pressure-yaml-navlink08 {
    font-weight: 500;
    text-decoration: underline;
  }
  .oil-pressure-yaml-navlink09 {
    font-style: normal;
    font-weight: 500;
    text-decoration: underline;
  }
  .oil-pressure-yaml-text {
    display: block;
    font-size: 26px;
    align-self: center;
    margin-left: auto;
    margin-right: auto;
  }
  .oil-pressure-yaml-footer {
    padding: var(--dl-space-space-unit);
  }
  .oil-pressure-yaml-separator {
    margin-top: 24px;
    margin-bottom: 24px;
  }
  .oil-pressure-yaml-container11 {
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
  }
  .oil-pressure-yaml-text11 {
    text-align: center;
    margin-bottom: 24px;
  }
  .oil-pressure-yaml-image3 {
    width: auto;
    height: 50px;
  }
}
