.landing-page-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  border-color: var(--dl-color-gray-black);
  border-width: 5px;
  flex-direction: column;
}
.landing-page-container1 {
  width: 100%;
  height: 300px;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
  background-size: cover;
  justify-content: flex-start;
  background-image: url("/website%20banner%20no%20logo%20or%20name.png");
}
.landing-page-container2 {
  border: 2px dashed rgba(120, 120, 120, 0.4);
  display: flex;
  position: relative;
}
.landing-page-image {
  width: 100px;
  align-self: center;
  margin-top: 40px;
  object-fit: cover;
}
.landing-page-container3 {
  width: 400px;
  height: 66px;
  display: flex;
  align-self: center;
  margin-top: 20px;
  align-items: flex-start;
}
.landing-page-text {
  display: block;
  font-size: 32px;
  align-self: center;
  font-style: normal;
  margin-top: 0px;
  font-weight: 800;
  margin-left: auto;
  margin-right: auto;
}
.landing-page-text01 {
  align-self: center;
  margin-top: 50px;
}
.landing-page-text02 {
  font-size: 14px;
  text-align: justify;
  padding-top: var(--dl-space-space-unit);
  border-color: var(--dl-color-gray-black);
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
}
.landing-page-link {
  color: #0000ff;
  text-decoration: underline;
}
.landing-page-text10 {
  margin-top: var(--dl-space-space-doubleunit);
}
.landing-page-container4 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-self: center;
  align-items: flex-start;
  justify-content: center;
}
.landing-page-link1 {
  display: contents;
}
.landing-page-image1 {
  width: auto;
  object-fit: cover;
  text-decoration: none;
}
.landing-page-text13 {
  font-style: normal;
  font-weight: 700;
}
.landing-page-navlink {
  color: #0000ff;
  text-decoration: underline;
}
.landing-page-footer {
  width: 100%;
  display: flex;
  max-width: 1400px;
  align-items: center;
  padding-top: 32px;
  padding-left: 48px;
  padding-right: 48px;
  flex-direction: column;
  padding-bottom: 32px;
  justify-content: space-between;
}
.landing-page-container5 {
  display: flex;
  max-width: 1400px;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
}
.landing-page-nav {
  flex: 0 0 auto;
  display: flex;
  margin-top: 0px;
  align-items: center;
  flex-direction: row;
}
.landing-page-navlink1 {
  margin-left: 0px;
  text-decoration: underline;
}
.landing-page-navlink2 {
  margin-left: var(--dl-space-space-unit);
  text-decoration: underline;
}
.landing-page-navlink3 {
  margin-left: var(--dl-space-space-unit);
  text-decoration: underline;
}
.landing-page-navlink4 {
  margin-left: var(--dl-space-space-unit);
  text-decoration: underline;
}
.landing-page-navlink5 {
  margin-left: var(--dl-space-space-unit);
  text-decoration: underline;
}
.landing-page-separator {
  flex: 0 0 auto;
  width: 100%;
  height: 0px;
  display: flex;
  margin-top: 32px;
  align-items: flex-start;
  margin-left: 0px;
  border-color: var(--dl-color-gray-900);
  border-style: solid;
  border-width: 1px;
  margin-right: 0px;
  margin-bottom: 32px;
  flex-direction: row;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.landing-page-container6 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.landing-page-icon-group {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.landing-page-link2 {
  display: contents;
}
.landing-page-image2 {
  width: 100px;
  object-fit: cover;
  text-decoration: none;
}
@media(max-width: 991px) {
  .landing-page-text02 {
    border-width: 0px;
  }
  .landing-page-image2 {
    width: auto;
    height: 50px;
  }
}
@media(max-width: 767px) {
  .landing-page-footer {
    padding-left: 32px;
    padding-right: 32px;
  }
  .landing-page-separator {
    margin-top: 24px;
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: 24px;
  }
  .landing-page-container6 {
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
  }
  .landing-page-text16 {
    margin-bottom: 24px;
  }
}
@media(max-width: 479px) {
  .landing-page-container {
    border-width: 3px;
  }
  .landing-page-text {
    display: block;
    font-size: 26px;
    align-self: center;
    font-style: normal;
    text-align: center;
    font-weight: 800;
    margin-left: auto;
    margin-right: auto;
  }
  .landing-page-text10 {
    text-align: center;
  }
  .landing-page-text13 {
    padding-left: var(--dl-space-space-doubleunit);
  }
  .landing-page-footer {
    padding: var(--dl-space-space-unit);
  }
  .landing-page-separator {
    margin-top: 24px;
    margin-bottom: 24px;
  }
  .landing-page-container6 {
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
  }
  .landing-page-text16 {
    text-align: center;
    margin-bottom: 24px;
  }
  .landing-page-image2 {
    width: auto;
    height: 50px;
  }
}
