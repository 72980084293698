.videos-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  border-color: var(--dl-color-gray-black);
  border-width: 5px;
  flex-direction: column;
}
.videos-container01 {
  width: 100%;
  height: 300px;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
  background-size: cover;
  justify-content: flex-start;
  background-image: url("/website%20banner%20no%20logo%20or%20name.png");
}
.videos-container02 {
  display: flex;
  position: relative;
}
.videos-navbar-interactive {
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  padding-top: 32px;
  padding-left: 48px;
  padding-right: 48px;
  padding-bottom: 32px;
  justify-content: space-between;
}
.videos-desktop-menu {
  display: flex;
}
.videos-nav {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  flex-direction: row;
}
.videos-navlink {
  font-style: normal;
  font-weight: 500;
  text-decoration: none;
}
.videos-navlink01 {
  font-style: normal;
  font-weight: 500;
  margin-left: 32px;
  text-decoration: none;
}
.videos-navlink02 {
  font-style: normal;
  font-weight: 500;
  margin-left: 32px;
  text-decoration: none;
}
.videos-navlink03 {
  font-style: normal;
  font-weight: 500;
  margin-left: 32px;
  text-decoration: none;
}
.videos-navlink04 {
  font-style: normal;
  font-weight: 500;
  margin-left: 32px;
  text-decoration: none;
}
.videos-btn-group {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.videos-burger-menu {
  display: none;
  align-items: center;
  justify-content: center;
}
.videos-icon {
  width: 32px;
  height: 32px;
}
.videos-mobile-menu {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  display: none;
  padding: 32px;
  z-index: 100;
  position: fixed;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fff;
}
.videos-nav1 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.videos-container03 {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 48px;
  justify-content: space-between;
}
.videos-menu-close {
  display: flex;
  align-items: center;
  justify-content: center;
}
.videos-nav2 {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.videos-navlink05 {
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.videos-navlink06 {
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.videos-navlink07 {
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.videos-navlink08 {
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.videos-navlink09 {
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.videos-container04 {
  flex: 0 0 auto;
  width: auto;
  border: 2px dashed rgba(120, 120, 120, 0.4);
  display: flex;
  margin-top: var(--dl-space-space-unit);
  align-items: center;
  flex-direction: row;
}
.videos-icon-group {
  display: flex;
}
.videos-icon2 {
  width: 16px;
  height: 16px;
  margin-right: 32px;
}
.videos-icon4 {
  width: 16px;
  height: 16px;
  margin-right: 32px;
}
.videos-icon6 {
  width: 16px;
  height: 16px;
}
.videos-image {
  width: 100px;
  align-self: center;
  margin-top: 40px;
  object-fit: cover;
}
.videos-container05 {
  width: 400px;
  height: 66px;
  display: flex;
  align-self: center;
  margin-top: 20px;
  align-items: flex-start;
}
.videos-text {
  display: block;
  font-size: 32px;
  align-self: center;
  font-style: normal;
  margin-top: 0px;
  font-weight: 800;
  margin-left: auto;
  margin-right: auto;
}
.videos-text01 {
  align-self: center;
  margin-top: 50px;
}
.videos-container06 {
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.videos-text02 {
  font-size: 14px;
  text-align: justify;
  padding-top: var(--dl-space-space-unit);
  border-color: var(--dl-color-gray-black);
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
}
.videos-link {
  color: #0000ff;
  text-decoration: underline;
}
.videos-container07 {
  width: auto;
  height: auto;
  display: flex;
  align-self: center;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.videos-container08 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.videos-link01 {
  display: contents;
}
.videos-image01 {
  width: 300px;
  object-fit: cover;
  padding-top: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  text-decoration: none;
}
.videos-link02 {
  display: contents;
}
.videos-image02 {
  width: 300px;
  object-fit: cover;
  padding-top: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  text-decoration: none;
}
.videos-link03 {
  display: contents;
}
.videos-image03 {
  width: 300px;
  object-fit: cover;
  padding-top: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  text-decoration: none;
}
.videos-link04 {
  display: contents;
}
.videos-image04 {
  width: 300px;
  object-fit: cover;
  padding-top: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  text-decoration: none;
}
.videos-link05 {
  display: contents;
}
.videos-image05 {
  width: 300px;
  object-fit: cover;
  padding-top: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  text-decoration: none;
}
.videos-link06 {
  display: contents;
}
.videos-image06 {
  width: 300px;
  object-fit: cover;
  padding-top: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  text-decoration: none;
}
.videos-link07 {
  display: contents;
}
.videos-image07 {
  width: 300px;
  object-fit: cover;
  padding-top: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  text-decoration: none;
}
.videos-link08 {
  display: contents;
}
.videos-image08 {
  width: 300px;
  object-fit: cover;
  padding-top: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  text-decoration: none;
}
.videos-container09 {
  flex: 0 0 auto;
  width: 300px;
  height: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.videos-link09 {
  display: contents;
}
.videos-image09 {
  width: 300px;
  object-fit: cover;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-unit);
  text-decoration: none;
}
.videos-link10 {
  display: contents;
}
.videos-image10 {
  width: 300px;
  object-fit: cover;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-unit);
  text-decoration: none;
}
.videos-link11 {
  display: contents;
}
.videos-image11 {
  width: 300px;
  object-fit: cover;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-unit);
  text-decoration: none;
}
.videos-link12 {
  display: contents;
}
.videos-image12 {
  width: 300px;
  object-fit: cover;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-unit);
  text-decoration: none;
}
.videos-link13 {
  display: contents;
}
.videos-image13 {
  width: 300px;
  object-fit: cover;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-unit);
  text-decoration: none;
}
.videos-link14 {
  display: contents;
}
.videos-image14 {
  width: 300px;
  object-fit: cover;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-unit);
  text-decoration: none;
}
.videos-link15 {
  display: contents;
}
.videos-image15 {
  width: 300px;
  object-fit: cover;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-unit);
  text-decoration: none;
}
.videos-link16 {
  display: contents;
}
.videos-image16 {
  width: 300px;
  object-fit: cover;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-unit);
  text-decoration: none;
}
.videos-cards-container {
  width: 100%;
  border: 2px dashed rgba(120, 120, 120, 0.4);
  display: flex;
  grid-gap: var(--dl-space-space-doubleunit);
  flex-wrap: wrap;
  max-width: 1000px;
  margin-top: var(--dl-space-space-doubleunit);
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-doubleunit);
  flex-direction: row;
  justify-content: space-between;
}
.videos-footer {
  width: 100%;
  display: flex;
  max-width: 1400px;
  align-items: center;
  padding-top: 32px;
  padding-left: 48px;
  padding-right: 48px;
  flex-direction: column;
  padding-bottom: 32px;
  justify-content: space-between;
}
.videos-container10 {
  display: flex;
  max-width: 1400px;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
}
.videos-nav3 {
  flex: 0 0 auto;
  display: flex;
  margin-top: 0px;
  align-items: center;
  flex-direction: row;
}
.videos-navlink10 {
  margin-left: 0px;
  text-decoration: underline;
}
.videos-navlink11 {
  margin-left: var(--dl-space-space-unit);
  text-decoration: underline;
}
.videos-navlink12 {
  margin-left: var(--dl-space-space-unit);
  text-decoration: underline;
}
.videos-navlink13 {
  margin-left: var(--dl-space-space-unit);
  text-decoration: underline;
}
.videos-navlink14 {
  margin-left: var(--dl-space-space-unit);
  text-decoration: underline;
}
.videos-separator {
  flex: 0 0 auto;
  width: 100%;
  height: 0px;
  display: flex;
  margin-top: 32px;
  align-items: flex-start;
  margin-left: 0px;
  border-color: var(--dl-color-gray-900);
  border-style: solid;
  border-width: 1px;
  margin-right: 0px;
  margin-bottom: 32px;
  flex-direction: row;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.videos-container11 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.videos-icon-group1 {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.videos-link17 {
  display: contents;
}
.videos-image17 {
  width: 100px;
  object-fit: cover;
  text-decoration: none;
}
@media(max-width: 991px) {
  .videos-navlink {
    font-style: normal;
    font-weight: 500;
  }
  .videos-navlink01 {
    font-style: normal;
    font-weight: 500;
  }
  .videos-navlink02 {
    font-style: normal;
    font-weight: 500;
  }
  .videos-navlink03 {
    font-style: normal;
    font-weight: 500;
  }
  .videos-navlink04 {
    font-style: normal;
    font-weight: 500;
  }
  .videos-text02 {
    border-width: 0px;
  }
  .videos-cards-container {
    justify-content: center;
  }
  .videos-image17 {
    width: auto;
    height: 50px;
  }
}
@media(max-width: 767px) {
  .videos-navbar-interactive {
    padding-left: 32px;
    padding-right: 32px;
  }
  .videos-navlink01 {
    margin-left: var(--dl-space-space-unit);
  }
  .videos-navlink02 {
    margin-left: var(--dl-space-space-unit);
  }
  .videos-navlink03 {
    margin-left: var(--dl-space-space-unit);
  }
  .videos-navlink04 {
    margin-left: var(--dl-space-space-unit);
  }
  .videos-footer {
    padding-left: 32px;
    padding-right: 32px;
  }
  .videos-separator {
    margin-top: 24px;
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: 24px;
  }
  .videos-container11 {
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
  }
  .videos-text11 {
    margin-bottom: 24px;
  }
}
@media(max-width: 479px) {
  .videos-container {
    border-width: 3px;
  }
  .videos-navbar-interactive {
    padding: var(--dl-space-space-unit);
    padding-bottom: 1em;
  }
  .videos-desktop-menu {
    display: none;
  }
  .videos-btn-group {
    display: none;
  }
  .videos-burger-menu {
    display: flex;
  }
  .videos-mobile-menu {
    padding: 16px;
  }
  .videos-navlink05 {
    font-style: normal;
    font-weight: 500;
    text-decoration: underline;
  }
  .videos-navlink06 {
    font-style: normal;
    font-weight: 500;
    text-decoration: underline;
  }
  .videos-navlink07 {
    font-weight: 500;
    text-decoration: underline;
  }
  .videos-navlink08 {
    font-weight: 500;
    text-decoration: underline;
  }
  .videos-navlink09 {
    font-style: normal;
    font-weight: 500;
    text-decoration: underline;
  }
  .videos-text {
    display: block;
    font-size: 26px;
    align-self: center;
    margin-left: auto;
    margin-right: auto;
  }
  .videos-container07 {
    width: 432px;
    align-items: flex-start;
    justify-content: center;
  }
  .videos-container08 {
    width: auto;
  }
  .videos-image01 {
    width: 200px;
    height: 113px;
    align-self: flex-end;
    border-color: var(--dl-color-gray-black);
    border-width: 0px;
    padding-left: var(--dl-space-space-unit);
  }
  .videos-image02 {
    width: 200px;
    height: 113px;
    align-self: flex-end;
    border-width: 0px;
  }
  .videos-image03 {
    width: 200px;
    height: 113px;
    align-self: flex-end;
    border-width: 0px;
  }
  .videos-image04 {
    width: 200px;
    height: 113px;
    align-self: flex-end;
    border-width: 0px;
  }
  .videos-image05 {
    width: 200px;
    height: 113px;
    align-self: flex-end;
    border-width: 0px;
  }
  .videos-image06 {
    width: 200px;
    height: 113px;
    align-self: flex-end;
    border-width: 0px;
  }
  .videos-image07 {
    width: 200px;
    height: 113px;
    align-self: flex-end;
    border-width: 0px;
  }
  .videos-image08 {
    width: 200px;
    height: 113px;
    align-self: flex-end;
    border-width: 0px;
    padding-left: var(--dl-space-space-unit);
  }
  .videos-container09 {
    width: 50%;
  }
  .videos-image09 {
    width: 200px;
    align-self: flex-start;
    border-color: var(--dl-color-gray-black);
    border-width: 0px;
    border-radius: var(--dl-radius-radius-radius4);
  }
  .videos-image10 {
    width: 200px;
    height: 113px;
    align-self: flex-start;
    border-color: var(--dl-color-gray-black);
    border-width: 0px;
    border-radius: var(--dl-radius-radius-radius4);
  }
  .videos-image11 {
    width: 200px;
    align-self: flex-start;
    border-width: 0px;
  }
  .videos-image12 {
    width: 200px;
    align-self: flex-start;
    border-width: 0px;
  }
  .videos-image13 {
    width: 200px;
    align-self: flex-start;
    border-width: 0px;
  }
  .videos-image14 {
    width: 200px;
    align-self: flex-start;
    border-width: 0px;
  }
  .videos-image15 {
    width: 200px;
    align-self: flex-start;
    border-width: 0px;
  }
  .videos-image16 {
    width: 200px;
    align-self: flex-start;
    border-width: 0px;
  }
  .videos-footer {
    padding: var(--dl-space-space-unit);
  }
  .videos-separator {
    margin-top: 24px;
    margin-bottom: 24px;
  }
  .videos-container11 {
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
  }
  .videos-text11 {
    text-align: center;
    margin-bottom: 24px;
  }
  .videos-image17 {
    width: auto;
    height: 50px;
  }
}
