.equipment-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  border-color: var(--dl-color-gray-black);
  border-width: 5px;
  flex-direction: column;
}
.equipment-container01 {
  width: 100%;
  height: 300px;
  display: flex;
  position: relative;
  align-self: center;
  align-items: flex-start;
  flex-direction: column;
  background-size: cover;
  justify-content: flex-start;
  background-image: url("/website%20banner%20no%20logo%20or%20name.png");
}
.equipment-container02 {
  display: flex;
  position: relative;
}
.equipment-navbar-interactive {
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  padding-top: 32px;
  padding-left: 48px;
  padding-right: 48px;
  padding-bottom: 32px;
  justify-content: space-between;
}
.equipment-desktop-menu {
  display: flex;
}
.equipment-nav {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  flex-direction: row;
}
.equipment-navlink {
  font-style: normal;
  font-weight: 500;
  text-decoration: none;
}
.equipment-navlink01 {
  font-style: normal;
  font-weight: 500;
  margin-left: 32px;
  text-decoration: none;
}
.equipment-navlink02 {
  font-style: normal;
  font-weight: 500;
  margin-left: 32px;
  text-decoration: none;
}
.equipment-navlink03 {
  font-style: normal;
  font-weight: 500;
  margin-left: 32px;
  text-decoration: none;
}
.equipment-navlink04 {
  font-style: normal;
  font-weight: 500;
  margin-left: 32px;
  text-decoration: none;
}
.equipment-btn-group {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.equipment-burger-menu {
  display: none;
  align-items: center;
  justify-content: center;
}
.equipment-icon {
  width: 32px;
  height: 32px;
}
.equipment-mobile-menu {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  display: none;
  padding: 32px;
  z-index: 100;
  position: fixed;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fff;
}
.equipment-nav1 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.equipment-container03 {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 48px;
  justify-content: space-between;
}
.equipment-menu-close {
  display: flex;
  align-items: center;
  justify-content: center;
}
.equipment-nav2 {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.equipment-navlink05 {
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.equipment-navlink06 {
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.equipment-navlink07 {
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.equipment-navlink08 {
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.equipment-navlink09 {
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.equipment-container04 {
  flex: 0 0 auto;
  width: auto;
  border: 2px dashed rgba(120, 120, 120, 0.4);
  display: flex;
  margin-top: var(--dl-space-space-unit);
  align-items: center;
  flex-direction: row;
}
.equipment-icon-group {
  display: flex;
}
.equipment-icon2 {
  width: 16px;
  height: 16px;
  margin-right: 32px;
}
.equipment-icon4 {
  width: 16px;
  height: 16px;
  margin-right: 32px;
}
.equipment-icon6 {
  width: 16px;
  height: 16px;
}
.equipment-image {
  width: 100px;
  align-self: center;
  margin-top: 40px;
  object-fit: cover;
}
.equipment-container05 {
  width: 400px;
  height: 66px;
  display: flex;
  align-self: center;
  margin-top: 20px;
  align-items: flex-start;
}
.equipment-text {
  display: block;
  font-size: 32px;
  align-self: center;
  font-style: normal;
  margin-top: 0px;
  font-weight: 800;
  margin-left: auto;
  margin-right: auto;
}
.equipment-text001 {
  align-self: center;
  margin-top: 50px;
}
.equipment-container06 {
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.equipment-text002 {
  font-size: 14px;
  text-align: justify;
  padding-top: var(--dl-space-space-unit);
  border-color: var(--dl-color-gray-black);
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
}
.equipment-container07 {
  width: 740px;
  height: auto;
  display: flex;
  align-self: center;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.equipment-text009 {
  font-size: 14px;
  text-align: justify;
  padding-top: var(--dl-space-space-unit);
  border-color: var(--dl-color-gray-black);
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
}
.equipment-text010 {
  font-style: normal;
  font-weight: 700;
  text-decoration: underline;
}
.equipment-text011 {
  font-style: normal;
  font-weight: 700;
}
.equipment-text012 {
  font-style: normal;
  font-weight: 700;
}
.equipment-text013 {
  font-style: normal;
  font-weight: 700;
}
.equipment-text014 {
  font-style: normal;
  font-weight: 700;
}
.equipment-text015 {
  font-style: normal;
  font-weight: 700;
}
.equipment-text016 {
  font-size: 14px;
}
.equipment-text019 {
  font-size: 14px;
}
.equipment-link {
  color: #0000ff;
  text-decoration: underline;
}
.equipment-text020 {
  color: #0000ff;
  font-size: 14px;
}
.equipment-link01 {
  color: #0000ff;
  text-decoration: underline;
}
.equipment-text021 {
  font-size: 14px;
}
.equipment-text024 {
  font-size: 14px;
}
.equipment-link02 {
  color: #0000ff;
  text-decoration: underline;
}
.equipment-text025 {
  color: #0000ff;
}
.equipment-text027 {
  font-style: normal;
  font-weight: 700;
  text-decoration: underline;
}
.equipment-text028 {
  font-style: normal;
  font-weight: 700;
}
.equipment-text029 {
  font-style: normal;
  font-weight: 700;
}
.equipment-text030 {
  font-style: normal;
  font-weight: 700;
}
.equipment-link03 {
  color: #0000ff;
  text-decoration: underline;
}
.equipment-text035 {
  color: #0000ff;
}
.equipment-link04 {
  color: #0000ff;
  text-decoration: underline;
}
.equipment-text038 {
  text-decoration: underline;
}
.equipment-text039 {
  font-style: normal;
  font-weight: 700;
  text-decoration: underline;
}
.equipment-text040 {
  font-style: normal;
  font-weight: 700;
}
.equipment-text041 {
  font-style: normal;
  font-weight: 700;
}
.equipment-text042 {
  font-style: normal;
  font-weight: 700;
}
.equipment-link05 {
  color: #0000ff;
}
.equipment-text049 {
  font-weight: 700;
  text-decoration: underline;
}
.equipment-text055 {
  font-weight: 700;
}
.equipment-link06 {
  color: #0000ff;
  text-decoration: underline;
}
.equipment-text060 {
  font-style: normal;
  font-weight: 700;
  text-decoration: underline;
}
.equipment-text061 {
  font-style: normal;
  font-weight: 700;
}
.equipment-text062 {
  font-style: normal;
  font-weight: 700;
}
.equipment-text063 {
  font-size: 14px;
}
.equipment-text064 {
  font-size: 14px;
}
.equipment-link07 {
  color: #0000ff;
  text-decoration: underline;
}
.equipment-text067 {
  font-size: 14px;
}
.equipment-text068 {
  font-style: normal;
  font-weight: 700;
}
.equipment-text069 {
  font-style: normal;
  font-weight: 700;
  text-decoration: underline;
}
.equipment-text070 {
  font-style: normal;
  font-weight: 700;
}
.equipment-text071 {
  font-style: normal;
  font-weight: 700;
}
.equipment-text073 {
  font-size: 14px;
}
.equipment-text074 {
  font-size: 14px;
}
.equipment-link08 {
  color: #0000ff;
  text-decoration: underline;
}
.equipment-text077 {
  font-size: 14px;
}
.equipment-text079 {
  font-style: normal;
  font-weight: 700;
  text-decoration: underline;
}
.equipment-text080 {
  font-style: normal;
  font-weight: 700;
}
.equipment-text081 {
  font-style: normal;
  font-weight: 700;
}
.equipment-text082 {
  font-style: normal;
  font-weight: 700;
}
.equipment-link09 {
  color: #0000ff;
  text-decoration: underline;
}
.equipment-text087 {
  color: #0000ff;
}
.equipment-link10 {
  color: #0000ff;
  text-decoration: underline;
}
.equipment-link11 {
  color: #0000ff;
  text-decoration: underline;
}
.equipment-text092 {
  font-size: 14px;
}
.equipment-text094 {
  font-style: normal;
  font-weight: 700;
  text-decoration: underline;
}
.equipment-text095 {
  font-style: normal;
  font-weight: 700;
}
.equipment-text096 {
  font-style: normal;
  font-weight: 700;
}
.equipment-text097 {
  font-style: normal;
  font-weight: 700;
}
.equipment-text101 {
  font-size: 14px;
}
.equipment-link12 {
  color: #0000ff;
  text-decoration: underline;
}
.equipment-text102 {
  font-size: 14px;
}
.equipment-text105 {
  font-size: 14px;
}
.equipment-link13 {
  color: #0000ff;
  text-decoration: underline;
}
.equipment-text106 {
  font-size: 14px;
}
.equipment-text108 {
  font-style: normal;
  font-weight: 700;
  text-decoration: underline;
}
.equipment-text109 {
  font-style: normal;
  font-weight: 700;
}
.equipment-text110 {
  font-style: normal;
  font-weight: 700;
}
.equipment-text111 {
  font-style: normal;
  font-weight: 700;
}
.equipment-text112 {
  font-size: 14px;
}
.equipment-text115 {
  font-size: 14px;
}
.equipment-link14 {
  color: #0000ff;
  text-decoration: underline;
}
.equipment-text116 {
  color: #0000ff;
  font-size: 14px;
}
.equipment-text119 {
  font-size: 14px;
}
.equipment-link15 {
  color: #0000ff;
  text-decoration: underline;
}
.equipment-text120 {
  color: #0000ff;
  font-size: 14px;
}
.equipment-text122 {
  font-style: normal;
  font-weight: 700;
  text-decoration: underline;
}
.equipment-text123 {
  font-style: normal;
  font-weight: 700;
}
.equipment-text124 {
  font-style: normal;
  font-weight: 700;
}
.equipment-text125 {
  font-style: normal;
  font-weight: 700;
}
.equipment-text126 {
  font-size: 14px;
}
.equipment-text129 {
  font-size: 14px;
}
.equipment-link16 {
  color: #0000ff;
  text-decoration: underline;
}
.equipment-text130 {
  color: #0000ff;
  font-size: 14px;
}
.equipment-text133 {
  font-size: 14px;
}
.equipment-link17 {
  color: #0000ff;
  text-decoration: underline;
}
.equipment-text134 {
  font-size: 14px;
}
.equipment-text136 {
  font-style: normal;
  font-weight: 700;
  text-decoration: underline;
}
.equipment-text137 {
  font-style: normal;
  font-weight: 700;
}
.equipment-text138 {
  font-style: normal;
  font-weight: 700;
}
.equipment-text139 {
  font-size: 14px;
}
.equipment-text142 {
  font-size: 14px;
}
.equipment-link18 {
  color: #0000ff;
  text-decoration: underline;
}
.equipment-text143 {
  color: #0000ff;
  font-size: 14px;
}
.equipment-link19 {
  color: #0000ff;
  text-decoration: underline;
}
.equipment-text144 {
  font-size: 14px;
}
.equipment-text147 {
  font-size: 14px;
}
.equipment-link20 {
  color: #0000ff;
  text-decoration: underline;
}
.equipment-text148 {
  color: #0000ff;
  font-size: 14px;
}
.equipment-link21 {
  color: #0000ff;
  text-decoration: underline;
}
.equipment-text149 {
  color: #0000ff;
}
.equipment-text151 {
  font-style: normal;
  font-weight: 700;
  text-decoration: underline;
}
.equipment-link22 {
  color: #0000ff;
  text-decoration: underline;
}
.equipment-link23 {
  color: #0000ff;
  text-decoration: underline;
}
.equipment-text162 {
  font-style: normal;
  font-weight: 700;
  text-decoration: underline;
}
.equipment-link24 {
  color: #0000ff;
  text-decoration: underline;
}
.equipment-link25 {
  color: #0000ff;
  text-decoration: underline;
}
.equipment-text173 {
  font-style: normal;
  font-weight: 700;
  text-decoration: underline;
}
.equipment-link26 {
  color: #0000ff;
  text-decoration: underline;
}
.equipment-text178 {
  color: #0000ff;
}
.equipment-link27 {
  color: #0000ff;
  text-decoration: underline;
}
.equipment-link28 {
  color: #0000ff;
  text-decoration: underline;
}
.equipment-text183 {
  color: #0000ff;
}
.equipment-text185 {
  font-style: normal;
  font-weight: 700;
  text-decoration: underline;
}
.equipment-link29 {
  color: #0000ff;
  text-decoration: underline;
}
.equipment-link30 {
  color: #0000ff;
  text-decoration: underline;
}
.equipment-text196 {
  font-style: normal;
  font-weight: 700;
  text-decoration: underline;
}
.equipment-link31 {
  color: #0000ff;
  text-decoration: underline;
}
.equipment-text203 {
  font-style: normal;
  font-weight: 700;
  text-decoration: underline;
}
.equipment-link32 {
  color: #0000ff;
  text-decoration: underline;
}
.equipment-text208 {
  color: #0000ff;
}
.equipment-link33 {
  color: #0000ff;
  text-decoration: underline;
}
.equipment-text212 {
  color: #0000ff;
}
.equipment-text214 {
  font-style: normal;
  font-weight: 700;
  text-decoration: underline;
}
.equipment-link34 {
  color: #0000ff;
  text-decoration: underline;
}
.equipment-link35 {
  color: #0000ff;
  text-decoration: underline;
}
.equipment-text223 {
  font-size: 14px;
}
.equipment-text224 {
  font-size: 14px;
}
.equipment-text225 {
  font-weight: 700;
  text-decoration: underline;
}
.equipment-link36 {
  color: #0000ff;
  text-decoration: underline;
}
.equipment-link37 {
  color: #0000ff;
  text-decoration: underline;
}
.equipment-text234 {
  font-size: 14px;
  text-decoration: underline;
}
.equipment-text236 {
  font-style: normal;
  font-weight: 700;
  text-decoration: underline;
}
.equipment-link39 {
  color: #0000ff;
  text-decoration: underline;
}
.equipment-link40 {
  color: #0000ff;
  text-decoration: underline;
}
.equipment-link41 {
  color: #0000ff;
  text-decoration: underline;
}
.equipment-text252 {
  font-style: normal;
  font-weight: 700;
  text-decoration: underline;
}
.equipment-link42 {
  color: #0000ff;
  text-decoration: underline;
}
.equipment-link43 {
  color: #0000ff;
  text-decoration: underline;
}
.equipment-text261 {
  color: #0000ff;
}
.equipment-text263 {
  font-style: normal;
  font-weight: 700;
  text-decoration: underline;
}
.equipment-link44 {
  color: #0000ff;
  text-decoration: underline;
}
.equipment-text268 {
  color: #0000ff;
}
.equipment-link45 {
  color: #0000ff;
  text-decoration: underline;
}
.equipment-link46 {
  color: #0000ff;
  text-decoration: underline;
}
.equipment-text275 {
  font-style: normal;
  font-weight: 700;
  text-decoration: underline;
}
.equipment-text276 {
  font-size: 14px;
}
.equipment-text277 {
  font-size: 14px;
}
.equipment-link47 {
  color: #0000ff;
  text-decoration: underline;
}
.equipment-text280 {
  text-decoration: underline;
}
.equipment-link49 {
  color: #0000ff;
  text-decoration: underline;
}
.equipment-text286 {
  font-style: normal;
  font-weight: 700;
  text-decoration: underline;
}
.equipment-link50 {
  color: #0000ff;
  text-decoration: underline;
}
.equipment-text291 {
  text-decoration: underline;
}
.equipment-link52 {
  color: #0000ff;
  text-decoration: underline;
}
.equipment-text295 {
  font-size: 14px;
}
.equipment-text297 {
  font-weight: 700;
  text-decoration: underline;
}
.equipment-link53 {
  color: #0000ff;
  text-decoration: underline;
}
.equipment-text304 {
  font-weight: 700;
  text-decoration: underline;
}
.equipment-link54 {
  color: #0000ff;
  text-decoration: underline;
}
.equipment-text311 {
  font-weight: 700;
  text-decoration: underline;
}
.equipment-link55 {
  color: #0000ff;
  text-decoration: underline;
}
.equipment-link56 {
  color: #0000ff;
  text-decoration: underline;
}
.equipment-text322 {
  font-weight: 700;
  text-decoration: underline;
}
.equipment-text323 {
  font-weight: 700;
  text-decoration: underline;
}
.equipment-text324 {
  font-weight: 700;
  text-decoration: underline;
}
.equipment-text326 {
  font-weight: 700;
  text-decoration: underline;
}
.equipment-text327 {
  font-weight: 700;
  text-decoration: underline;
}
.equipment-text329 {
  font-weight: 700;
}
.equipment-text331 {
  font-weight: 700;
  text-decoration: underline;
}
.equipment-link57 {
  color: #0000ff;
  text-decoration: underline;
}
.equipment-text332 {
  font-size: 14px;
  text-decoration: underline;
}
.equipment-cards-container {
  width: 100%;
  border: 2px dashed rgba(120, 120, 120, 0.4);
  display: flex;
  grid-gap: var(--dl-space-space-doubleunit);
  flex-wrap: wrap;
  max-width: 1000px;
  margin-top: var(--dl-space-space-doubleunit);
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-doubleunit);
  flex-direction: row;
  justify-content: space-between;
}
.equipment-footer {
  width: 100%;
  display: flex;
  max-width: 1400px;
  align-items: center;
  padding-top: 32px;
  padding-left: 48px;
  padding-right: 48px;
  flex-direction: column;
  padding-bottom: 32px;
  justify-content: space-between;
}
.equipment-container08 {
  display: flex;
  max-width: 1400px;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
}
.equipment-nav3 {
  flex: 0 0 auto;
  display: flex;
  margin-top: 0px;
  align-items: center;
  flex-direction: row;
}
.equipment-navlink10 {
  margin-left: 0px;
  text-decoration: underline;
}
.equipment-navlink11 {
  margin-left: var(--dl-space-space-unit);
  text-decoration: underline;
}
.equipment-navlink12 {
  margin-left: var(--dl-space-space-unit);
  text-decoration: underline;
}
.equipment-navlink13 {
  margin-left: var(--dl-space-space-unit);
  text-decoration: underline;
}
.equipment-navlink14 {
  margin-left: var(--dl-space-space-unit);
  text-decoration: underline;
}
.equipment-separator {
  flex: 0 0 auto;
  width: 100%;
  height: 0px;
  display: flex;
  margin-top: 32px;
  align-items: flex-start;
  margin-left: 0px;
  border-color: var(--dl-color-gray-900);
  border-style: solid;
  border-width: 1px;
  margin-right: 0px;
  margin-bottom: 32px;
  flex-direction: row;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.equipment-container09 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.equipment-icon-group1 {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.equipment-link59 {
  display: contents;
}
.equipment-image1 {
  width: 100px;
  object-fit: cover;
  text-decoration: none;
}
@media(max-width: 991px) {
  .equipment-navlink {
    font-style: normal;
    font-weight: 500;
  }
  .equipment-navlink01 {
    font-style: normal;
    font-weight: 500;
  }
  .equipment-navlink02 {
    font-style: normal;
    font-weight: 500;
  }
  .equipment-navlink03 {
    font-style: normal;
    font-weight: 500;
  }
  .equipment-navlink04 {
    font-style: normal;
    font-weight: 500;
  }
  .equipment-text002 {
    border-width: 0px;
  }
  .equipment-text009 {
    border-width: 0px;
  }
  .equipment-cards-container {
    justify-content: center;
  }
  .equipment-image1 {
    width: auto;
    height: 50px;
  }
}
@media(max-width: 767px) {
  .equipment-navbar-interactive {
    padding-left: 32px;
    padding-right: 32px;
  }
  .equipment-navlink01 {
    margin-left: var(--dl-space-space-unit);
  }
  .equipment-navlink02 {
    margin-left: var(--dl-space-space-unit);
  }
  .equipment-navlink03 {
    margin-left: var(--dl-space-space-unit);
  }
  .equipment-navlink04 {
    margin-left: var(--dl-space-space-unit);
  }
  .equipment-footer {
    padding-left: 32px;
    padding-right: 32px;
  }
  .equipment-separator {
    margin-top: 24px;
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: 24px;
  }
  .equipment-container09 {
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
  }
  .equipment-text338 {
    margin-bottom: 24px;
  }
}
@media(max-width: 479px) {
  .equipment-container {
    border-width: 3px;
  }
  .equipment-navbar-interactive {
    padding: var(--dl-space-space-unit);
    padding-bottom: 1em;
  }
  .equipment-desktop-menu {
    display: none;
  }
  .equipment-btn-group {
    display: none;
  }
  .equipment-burger-menu {
    display: flex;
  }
  .equipment-mobile-menu {
    padding: 16px;
  }
  .equipment-navlink05 {
    font-style: normal;
    font-weight: 500;
    text-decoration: underline;
  }
  .equipment-navlink06 {
    font-style: normal;
    font-weight: 500;
    text-decoration: underline;
  }
  .equipment-navlink07 {
    font-weight: 500;
    text-decoration: underline;
  }
  .equipment-navlink08 {
    font-weight: 500;
    text-decoration: underline;
  }
  .equipment-navlink09 {
    font-style: normal;
    font-weight: 500;
    text-decoration: underline;
  }
  .equipment-text {
    display: block;
    font-size: 26px;
    align-self: center;
    margin-left: auto;
    margin-right: auto;
  }
  .equipment-container07 {
    width: auto;
  }
  .equipment-footer {
    padding: var(--dl-space-space-unit);
  }
  .equipment-separator {
    margin-top: 24px;
    margin-bottom: 24px;
  }
  .equipment-container09 {
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
  }
  .equipment-text338 {
    text-align: center;
    margin-bottom: 24px;
  }
  .equipment-image1 {
    width: auto;
    height: 50px;
  }
}
